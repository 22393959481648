import { Button, Dialog, DialogActions, DialogContent, Paper, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Bar, PolarArea } from 'react-chartjs-2';

import ChangeBox from './ChangeBox';
import Map from './Map';
import connect from '../../lib/connect';
import LoadingButton from '../partial/LoadingButton';

interface ReportResponse {
  isTotals?: boolean;
  state: string;
  country: string;
  currentPeriod: {
    revenue: number;
    users: number;
    orders: number;
    conversions: number;
  };
  previewsPeriod: {
    revenue: number;
    users: number;
    orders: number;
    conversions: number;
  };
}

interface Props extends GlobalProps {}

interface State {
  preventRefresh: boolean;
  datesModal: boolean;
  channels: any[];
  start: any;
  end: any;
  period: string;
  type: string;
  view: string;
  usaReport: ReportResponse[];
  worldReport: ReportResponse[];
  polarChartData: any;
  barChartData: any;
  salesCoordinates: any[];
  isChartJSRegistered: boolean;
}

const styles = (theme: any): any => ({
  flexColumn1: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    flexGrow: 5,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  flexColumn2: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  card1: {
    display: 'flex',
    flexDirection: 'column',
    height: 300,
    padding: theme.spacing(1),
    width: '100%',
  },
  card2: {
    display: 'flex',
    flexDirection: 'column',
    height: 300,
    padding: theme.spacing(1),
    width: '100%',
  },
  map: {
    minWidth: 300,
    marginBottom: theme.spacing(1),
    '&:last-child': { marginBottom: 0 },
  },
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    overflow: 'visible',
    width: '100%',
    marginBottom: theme.spacing(1),
    '&:last-child': { marginBottom: 0 },
  },
  box: {
    width: '49%',
    maxWidth: 1000,
    minWidth: 300,
    minHeight: 30.75,
    marginBottom: theme.spacing(1),
  },
  changeBox: { minWidth: `calc(50% - ${parseInt(theme.spacing(1))}px)` },
  flex: {
    display: 'flex',
    overflow: 'visible',
    width: '100%',
  },
});
let preventRefreshTimer;
let autoRefreshTimer;

class Dashboard extends Component<Props, State> {
  chartOptions: any;

  polarChartOptions: any;

  autoRefreshListener: any;

  constructor(props) {
    super(props);

    const stub = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 1,
        },
      ],
    };

    this.state = {
      preventRefresh: false,
      datesModal: false,
      start: null,
      end: null,
      channels: [],
      period: 'today',
      type: 'revenue',
      view: 'world',
      usaReport: [],
      worldReport: [],
      salesCoordinates: [],
      polarChartData: stub,
      barChartData: stub,
      isChartJSRegistered: false,
    };

    this.chartOptions = {
      plugins: {
        // decimation: { enabled: true, algorithm: 'lttb', samples: 100 },
        legend: { display: false },
      },
      scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
      layout: {
        padding: {
          top: 15,
          bottom: 5,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      parsing: false,
      normalized: true,
    };
    this.polarChartOptions = {
      scales: {
        xAxes: [{ display: false }],
        yAxes: [
          {
            display: false,
            ticks: { beginAtZero: true },
          },
        ],
      },
      layout: {
        padding: {
          top: 15,
          bottom: 14,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      parsing: true,
      normalized: true,
    };
  }

  componentDidMount(): void {
    const { socket } = this.props;
    this.autoRefresh()().catch(this.props.logger.error);
    this.autoRefreshListener = this.autoRefreshListener || this.autoRefresh(true);
    socket.service('v1/objects/customer-orders').removeListener('created', this.autoRefreshListener);
    socket.service('v1/objects/customer-orders').removeListener('patched', this.autoRefreshListener);
    socket.service('v1/objects/customer-orders').on('created', this.autoRefreshListener);
    socket.service('v1/objects/customer-orders').on('patched', this.autoRefreshListener);
  }

  componentWillMount(): void {
    ChartJS.register(CategoryScale, RadialLinearScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);
    this.setState({ isChartJSRegistered: true });
  }

  componentWillUnmount(): void {
    const { socket } = this.props;
    if (this.autoRefreshListener) {
      socket.service('v1/objects/customer-orders').removeListener('create', this.autoRefreshListener);
      socket.service('v1/objects/customer-orders').removeListener('patched', this.autoRefreshListener);
      if (autoRefreshTimer) clearTimeout(autoRefreshTimer);
      if (preventRefreshTimer) clearTimeout(preventRefreshTimer);
    }

    ChartJS.unregister(CategoryScale, RadialLinearScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);
  }

  autoRefresh =
    (skipLoader = false, forceReload = false) =>
    async () => {
      const { view, preventRefresh } = this.state;
      const { isLoading, toggleSnack, logger, user } = this.props;

      if (!forceReload && (preventRefresh || !user.hasRule(['dashboard-preview']))) return;
      if (autoRefreshTimer) clearTimeout(autoRefreshTimer);
      if (preventRefreshTimer) clearTimeout(preventRefreshTimer);

      this.setState({ preventRefresh: true });
      preventRefreshTimer = setTimeout(() => this.setState({ preventRefresh: false }), 1000 * 30);
      autoRefreshTimer = setTimeout(() => this.autoRefresh(true)(), 1000 * 60 * 5);

      if (!skipLoader) await isLoading(true);

      try {
        if (view === 'usa') {
          await Promise.all([
            this.loadUsaData(),
            this.loadChartData(),
            this.loadBarData(),
            // this.loadMarkersData(),
          ]);
        } else {
          await Promise.all([
            this.loadWorldData(),
            this.loadChartData(),
            this.loadBarData(),
            // this.loadMarkersData(),
          ]);
        }
      } catch (err: any) {
        logger.error(err);
        toggleSnack(err.message);
      }

      if (!skipLoader) await isLoading(false);
    };

  selectChannels = value => {
    let channels = [...value];

    if (channels.length === 1 && channels?.[0]?.value === 'all') {
      channels = [{ label: 'All', value: 'all' }];
    } else if (channels.length > 1 && channels.find(c => c.value === 'all')) {
      const idx = channels.findIndex(c => c.value === 'all');
      channels.splice(idx, 1);
    }

    this.setState({ channels }, this.autoRefresh(false, true));
  };

  onChange = (state: string) => e => {
    if (state === 'channels') return this.selectChannels(e);
    const { value } = e.target || {};
    // @ts-ignore
    this.setState({ [state]: value }, async () => {
      if (state === 'period' && value === 'custom') {
        this.toggleDatesModal();
      } else {
        this.setState({ start: null, end: null });
      }

      if (['period', 'view'].includes(state) && value !== 'custom') {
        await this.autoRefresh(false, true)();
      }
    });
  };

  loadChartData = async () => {
    const { api } = this.props;

    const polarChartData = await api.service('v1/reports/charts/inventory').find({});

    this.setState({ polarChartData });
  };

  loadBarData = async () => {
    const { api } = this.props;
    const { period, start, end, channels } = this.state;

    const barChartData = await api.service('v1/reports/charts/top-selling-items').create({
      channels: channels.map(c => c.value),
      period,
      limit: 50,
      start,
      end,
    });

    this.setState({ barChartData });
  };

  loadUsaData = async () => {
    const { api } = this.props;
    const { period, start, end, channels } = this.state;

    const usaReport = await api.service('v1/reports/map/usa').create({
      channels: channels.map(c => c.value),
      period,
      start,
      end,
    });

    this.setState({ usaReport });
  };

  loadWorldData = async () => {
    const { api } = this.props;
    const { period, start, end, channels } = this.state;

    const worldReport = await api.service('v1/reports/map/world').create({
      channels: channels.map(c => c.value),
      period,
      start,
      end,
    });

    this.setState({ worldReport });
  };

  loadMarkersData = async () => {
    const { api } = this.props;
    const { period, start, end, view, channels } = this.state;

    const salesCoordinates = await api.service('v1/reports/map/markers').create({
      channels: channels.map(c => c.value),
      period,
      start,
      end,
      country: view === 'usa' ? 'usa' : null,
    });

    this.setState({ salesCoordinates });
  };

  getTotalData = (isUsa?: boolean) => {
    const { usaReport, worldReport } = this.state;

    if (isUsa) {
      const totals = usaReport.find(r => r.isTotals);

      if (totals) {
        return totals;
      }

      return {
        currentPeriod: {
          revenue: 0,
          users: 0,
          orders: 0,
          conversions: 0,
        },
        previewsPeriod: {
          revenue: 0,
          users: 0,
          orders: 0,
          conversions: 0,
        },
      };
    }

    const totals = worldReport.find(r => r.isTotals);

    if (totals) {
      return totals;
    }

    return {
      currentPeriod: {
        revenue: 0,
        users: 0,
        orders: 0,
        conversions: 0,
      },
      previewsPeriod: {
        revenue: 0,
        users: 0,
        orders: 0,
        conversions: 0,
      },
    };
  };

  normalizePeriod = (period: string) => {
    switch (period) {
      case 'today':
        return 'YESTERDAY';
      case 'yesterday':
        return 'DAY BEFORE';
      case 'last-hour':
        return 'ONE HOUR AGO';
      case 'last-24-hours':
        return '24 HOURS AGO';
      case 'last-7-days':
        return '7 DAYS AGO';
      case 'last-30-days':
        return '30 DAYS AGO';
      case 'previous-week':
      case '3-months':
      case '1-year':
      case 'custom':
        return 'PREVIOUS PERIOD';
    }
  };

  toggleDatesModal = () => {
    const { datesModal } = this.state;
    this.setState({ datesModal: !datesModal });
  };

  searchByCustomDate = async () => {
    await this.autoRefresh(false, true)();

    this.toggleDatesModal();
  };

  handleDateChange =
    (state: string): any =>
    (date: Date): void => {
      // @ts-ignore
      this.setState({ [state]: date });
    };

  render() {
    const { classes, redux } = this.props;
    const {
      polarChartData,
      view,
      period,
      type,
      usaReport,
      worldReport,
      barChartData,
      datesModal,
      start,
      end,
      salesCoordinates,
      channels,
      isChartJSRegistered,
    } = this.state;
    const isUsa = view === 'usa';
    const totals = this.getTotalData(isUsa);
    const normalizedPeriod = this.normalizePeriod(period);
    const currency = redux?.state?.user?.tenant?.configs?.general?.defaultCurrency;

    if (!isChartJSRegistered) return <div />;

    return (
      <div className={classes.root}>
        <Dialog classes={{ paper: classes.dialogPaper }} open={datesModal} onClose={this.toggleDatesModal}>
          <form onSubmit={e => e.preventDefault()}>
            <DialogContent>
              <DatePicker
                className={classNames(classes.marginRight2, classes.marginBottom1)}
                value={start}
                format="MM/DD/YYYY"
                label="from"
                onChange={this.handleDateChange('start')}
              />
              <DatePicker value={end} format="MM/DD/YYYY" label="to" onChange={this.handleDateChange('end')} />
            </DialogContent>
            <DialogActions className={classNames(classes.marginRight1, classes.marginLeft1)}>
              <Button size="small" color="secondary" onClick={this.toggleDatesModal}>
                CLOSE
              </Button>
              <LoadingButton
                className={classNames(classes.loadingBtn, classes.noMargin, classes.marginLeft2)}
                color="primary"
                type="button"
                onClick={this.searchByCustomDate}
                disabled={redux.state.loading}
                loading={redux.state.loading}
                label="SAVE"
              />
            </DialogActions>
          </form>
        </Dialog>
        <div className={classes.flex}>
          <div className={classes.flexColumn1}>
            <Map
              className={classes.map}
              reportData={isUsa ? usaReport : worldReport}
              channels={channels}
              period={period}
              type={type}
              view={view}
              start={start}
              end={end}
              salesCoordinates={salesCoordinates}
              onChange={this.onChange}
            />
            <Paper className={classes.card1}>
              <Typography variant="subtitle2" color="textSecondary">
                TOP 25 SELLING ITEMS
              </Typography>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Bar data={barChartData} options={this.chartOptions} redraw />
              </div>
            </Paper>
          </div>
          <div className={classes.flexColumn2}>
            <ChangeBox
              className={classes.marginBottom2}
              title="REVENUE"
              formatterStyle="currency"
              currentAmount={totals.currentPeriod.revenue}
              period={normalizedPeriod}
              previewsAmount={totals.previewsPeriod.revenue}
              currency={currency}
            />
            <ChangeBox
              className={classes.marginBottom2}
              title="NEW ORDERS"
              currentAmount={totals.currentPeriod.orders}
              period={normalizedPeriod}
              previewsAmount={totals.previewsPeriod.orders}
            />
            <ChangeBox
              className={classes.marginBottom2}
              title="NEW CUSTOMERS"
              currentAmount={totals.currentPeriod.users}
              period={normalizedPeriod}
              previewsAmount={totals.previewsPeriod.users}
            />
            <ChangeBox
              className={classes.marginBottom2}
              title="CONVERSIONS"
              currentAmount={totals.currentPeriod.conversions}
              period={normalizedPeriod}
              previewsAmount={totals.previewsPeriod.conversions}
            />
            <Paper className={classes.card2}>
              <Typography variant="subtitle2" color="textSecondary">
                INVENTORY
              </Typography>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                }}
              >
                <PolarArea data={polarChartData} options={this.polarChartOptions} redraw />
              </div>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

export default connect({
  styles,
})(Dashboard);
