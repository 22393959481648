import Head from 'next/head';
import React, { Component } from 'react';

import Dashboard from '../components/Dashboard';
import Layout from '../components/layout/Layout';
import NoAccess from '../components/partial/NoAccess';
import connect from '../lib/connect-page';

interface Props extends GlobalProps {}

class Page extends Component<Props> {
  render() {
    const { user } = this.props;

    if (!user.hasRule(['dashboard-preview'])) {
      return (
        <Layout>
          <NoAccess />
        </Layout>
      );
    }

    return (
      <Layout>
        <Head>
          <title key="title">Dashboard | Kentro.io</title>
        </Head>
        <Dashboard />
      </Layout>
    );
  }
}

export default connect()(Page);
