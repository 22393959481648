import { Paper, TextField, Tooltip, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import Flag from 'react-country-flag';
import { ComposableMap as CM, Geographies, Geography } from 'react-simple-maps';
import states from 'us-state-codes';

import connect from '../../lib/connect';
import CustomSelect from '../partial/CustomSelect';

interface ReportResponse {
  isTotals?: boolean;
  state: string;
  country: string;
  currentPeriod: {
    revenue: number;
    users: number;
    orders: number;
  };
  previewsPeriod: {
    revenue: number;
    users: number;
    orders: number;
  };
}

interface Props extends GlobalProps {
  className?: string;
  channels: any[];
  period: string;
  type: string;
  view: string;
  reportData: ReportResponse[];
  onChange: any;
  end: string;
  start: string;
  salesCoordinates: any[];
}

interface TooltipProps extends GlobalProps {
  className?: string;
  channels: any[];
  period: string;
  type: string;
  view: string;
  reportData: ReportResponse[];
  onChange: any;
  end: string;
  start: string;
  salesCoordinates: any[];
  changeRevenue: number;
  changeUsers: number;
  changeOrders: number;
  isUsa: boolean;
  g: any;
  totals: any;
  int: any;
}

interface State {
  selectedMarker: any;
  selectedGeography: any;
  colorPallet: any;
}

const styles = (theme: any): any => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  map: {
    width: '100%',
    margin: '0 auto',
    display: 'block',
    height: 'auto',
  },
  navBar: {
    display: 'flex',
    overflow: 'visible',
    width: `calc(100% - ${parseInt(theme.spacing(2))}px)`,
    padding: theme.spacing(1),
    paddingBottom: 0,
    marginBottom: 0,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  wrapper: {
    width: `calc(100% - ${parseInt(theme.spacing(2))}px)`,
    // maxWidth: 1500,
    margin: '0 auto',
  },
  sourceWidth: { minWidth: 80 },
  tooltip: {
    width: 'auto',
    minWidth: 200,
    maxWidth: 700,
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    '& b': { fontWeight: theme.typography.fontWeightMedium },
  },
  menu: { width: '100%' },
  w1: { width: 144 },
  w2: { width: 150 },
  w3: { width: 90 },
  textAreaField: {
    width: 400,
    minHeight: 30.75,
    maxWidth: '100%',
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    '&:last-child': { marginRight: 0 },
  },
});

const MapTooltip = (props: TooltipProps) => {
  const { classes, changeRevenue, changeUsers, changeOrders, isUsa, g, totals, int } = props;

  return (
    <Fragment>
      <div className={classes.flex}>
        {(isUsa || (g.properties && g.properties.wb_a2)) && (
          <Flag styleProps={{ width: 19, height: 19, marginRight: 4 }} code={isUsa ? 'us' : g.properties.wb_a2} svg />
        )}
        <Typography variant="subtitle2" color="textPrimary">
          {g.properties.name.toUpperCase()}
        </Typography>
      </div>
      <div className={classNames(classes.flex, classes.spaceBetween)}>
        <div className={classNames(classes.flexColumn, classes.preventNewLine, classes.sourceWidth)}>
          <Typography variant="body2" color="textSecondary">
            source
          </Typography>
          <Typography variant="body2">revenue</Typography>
          <Typography variant="body2">orders</Typography>
          <Typography variant="body2">customers</Typography>
        </div>
        <div className={classNames(classes.flexColumn, classes.flexAlignEnd)}>
          <Typography variant="body2" color="textSecondary">
            current
          </Typography>
          <Typography variant="body2">{int.format(totals.currentPeriod.revenue)}</Typography>
          <Typography variant="body2">{totals.currentPeriod.orders}</Typography>
          <Typography variant="body2">{totals.currentPeriod.users}</Typography>
        </div>
        <div className={classNames(classes.flexColumn, classes.flexAlignEnd)}>
          <Typography variant="body2" color="textSecondary">
            previews
          </Typography>
          <Typography variant="body2">{int.format(totals.previewsPeriod.revenue)}</Typography>
          <Typography variant="body2">{totals.previewsPeriod.users}</Typography>
          <Typography variant="body2">{totals.previewsPeriod.orders}</Typography>
        </div>
        <div className={classNames(classes.flexColumn, classes.flexAlignEnd)}>
          <Typography variant="body2" color="textSecondary">
            change
          </Typography>
          <div className={classes.flex}>
            <Typography variant="body2" color={changeRevenue > 0 ? 'primary' : 'secondary'}>
              {changeRevenue.toFixed(2)}%
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography variant="body2" color={changeUsers > 0 ? 'primary' : 'secondary'}>
              {changeUsers.toFixed(2)}%
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography variant="body2" color={changeOrders > 0 ? 'primary' : 'secondary'}>
              {changeOrders.toFixed(2)}%
            </Typography>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const ComposableMap = CM as any;

class Map extends Component<Props, State> {
  state = {
    selectedMarker: null,
    selectedGeography: {},
    colorPallet: { init: false },
  };

  UNSAFE_componentWillReceiveProps(props): void {
    const { reportData = [] } = props;

    // if (this.state.colorPallet.init && props.view === this.props.view) return;

    const colorPallet = {
      revenue: this.getColorPallet('revenue', reportData),
      orders: this.getColorPallet('orders', reportData),
      users: this.getColorPallet('users', reportData),
      init: true,
    };
    this.setState({ colorPallet });
  }

  getColorPallet = (type: string, reportData: any[]): any[] => {
    const data = reportData.filter(d => !d.isTotals && d?.currentPeriod?.[type]);
    const max =
      data.sort((a, b) => (a.currentPeriod[type] > b.currentPeriod[type] ? -1 : 1))?.[0]?.currentPeriod?.[type] || 0;
    const min =
      data.sort((a, b) => (a.currentPeriod[type] > b.currentPeriod[type] ? 1 : -1))?.[0]?.currentPeriod?.[type] || 0;
    const step = Number(((max - min) / 9).toFixed(0));

    return [
      { min, max: min + step, color: green[100] },
      { min: min + step * 1, max: min + step * 2, color: green[200] },
      { min: min + step * 2, max: min + step * 3, color: green[300] },
      { min: min + step * 3, max: min + step * 4, color: green[400] },
      { min: min + step * 4, max: min + step * 5, color: green[500] },
      { min: min + step * 5, max: min + step * 6, color: green[600] },
      { min: min + step * 6, max: min + step * 7, color: green[700] },
      { min: min + step * 7, max: min + step * 8, color: green[800] },
      { min: min + step * 8, max: Infinity, color: green[900] },
    ].reverse();
  };

  getColorFromPallet = (type: string, value: number) => {
    const { colorPallet } = this.state;
    const result = colorPallet?.[type]?.find(
      p => p.min && p.max && ((value >= p.min && value < p.max) || value > p.max)
    );
    if (!result?.color) return '#E2E6E7';
    return result.color;
  };

  getData = (isUsa: boolean, geo) => {
    const { reportData } = this.props;

    if (!reportData?.[0]) {
      return {
        currentPeriod: {
          revenue: 0,
          users: 0,
          orders: 0,
        },
        previewsPeriod: {
          revenue: 0,
          users: 0,
          orders: 0,
        },
      };
    }

    if (isUsa) {
      const state = reportData.find(
        r =>
          typeof r.state === 'string' &&
          r.state.toUpperCase() === (states.getStateCodeByStateName(geo?.properties?.name || '') || '').toUpperCase()
      );
      return (
        state || {
          currentPeriod: {
            revenue: 0,
            users: 0,
            orders: 0,
          },
          previewsPeriod: {
            revenue: 0,
            users: 0,
            orders: 0,
          },
        }
      );
    }

    const country = reportData.find(
      r => typeof r.country === 'string' && r.country.toUpperCase() === (geo?.properties?.iso_a2 || '').toUpperCase()
    );
    return (
      country || {
        currentPeriod: {
          revenue: 0,
          users: 0,
          orders: 0,
        },
        previewsPeriod: {
          revenue: 0,
          users: 0,
          orders: 0,
        },
      }
    );
  };

  markerOnMouseEnter = (selectedMarker: string) => () => {
    this.setState({ selectedMarker });
  };

  markerOnMouseLeave = () => {
    this.setState({ selectedMarker: null });
  };

  geographyOnMouseEnter = (id: number) => () => {
    const { selectedGeography } = this.state;
    selectedGeography[id] = true;
    this.setState({ selectedGeography });
  };

  geographyOnMouseLeave = (id: number) => () => {
    const { selectedGeography } = this.state;
    selectedGeography[id] = false;
    this.setState({ selectedGeography });
  };

  render() {
    const {
      classes,
      className,
      onChange,
      period,
      type,
      view,
      start,
      end,
      channels,
      redux: {
        state: { user },
      },
      // salesCoordinates,
    } = this.props;
    const {
      // selectedMarker,
      selectedGeography,
    } = this.state;
    const isUsa = view === 'usa';
    const usa = '/usa-map.json';
    const world = '/world-map.json';
    const int = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const colors = [100, 200, 300, 400, 500, 600, 700, 800, 900];

    return (
      <Paper className={classNames(classes.root, classes.flexColumn, classes.flexStart, className || null)}>
        <div className={classes.navBar}>
          <div className={classes.flexColumn}>
            <div className={classes.marginBottom1}>
              <CustomSelect
                className={classes.textAreaField}
                label="channels"
                placeholder="all"
                value={channels}
                onChange={onChange('channels')}
                schema={{ label: 'name', value: '_id' }}
                query={{ type: { $ne: 'default' } }}
                service="v1/objects/sales-channels"
                closeMenuOnSelect
                multi
              />
              <TextField
                className={classNames(classes.marginRight2, classes.marginBottom1, classes.w1)}
                SelectProps={{ native: true }}
                value={period}
                onChange={onChange('period')}
                margin="none"
                variant="outlined"
                select
              >
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="last-hour">Last Hour</option>
                <option value="last-24-hours">Last 24 Hours</option>
                <option value="last-7-days">Last 7 Days</option>
                <option value="last-30-days">Last 30 Days</option>
                <option value="previous-week">Previous Week</option>
                <option value="3-months">3 Months</option>
                <option value="1-year">1 Year</option>
                <option value="custom">Custom</option>
              </TextField>
              <TextField
                className={classNames(classes.marginRight2, classes.marginBottom1, classes.w2)}
                SelectProps={{ native: true }}
                value={type}
                onChange={onChange('type')}
                margin="none"
                variant="outlined"
                select
              >
                <option value="revenue">Revenue</option>
                <option value="orders">New Orders</option>
                <option value="users">New Customers</option>
              </TextField>
              <TextField
                className={classNames(classes.marginRight2, classes.w3)}
                SelectProps={{ native: true }}
                value={view}
                onChange={onChange('view')}
                margin="none"
                variant="outlined"
                select
              >
                <option value="usa">USA</option>
                <option value="world">World</option>
              </TextField>
            </div>
            {period === 'custom' && start && end && (
              <div>
                <Typography variant="caption" color="textSecondary">
                  {moment(start).format('MM/DD/YYYY')} - {moment(end).format('MM/DD/YYYY')}
                </Typography>
              </div>
            )}
          </div>
          <div className={classes.flex}>
            {colors.map(c => (
              <Tooltip key={c} title={`change: +${c === 50 ? '10' : (c + 100) / 10}%`}>
                <div
                  style={{
                    backgroundColor: green[c],
                    textAlign: 'center',
                    color: '#fff',
                    height: 20,
                    width: 15,
                  }}
                />
              </Tooltip>
            ))}
          </div>
        </div>
        <div className={classes.wrapper}>
          <ComposableMap
            projection={isUsa ? 'geoAlbersUsa' : 'geoEquirectangular'}
            projectionConfig={{ center: isUsa ? [-97, 40] : [0, 15] }}
            width={isUsa ? 830 : 980}
            height={isUsa ? 551 : 420}
            style={{ width: '100%', height: 'auto' }}
          >
            <Geographies geography={isUsa ? usa : world}>
              {({ geographies }: any) => {
                return geographies?.map((g: any, idx) => {
                  const totals = this.getData(isUsa, g);
                  const changeRevenue = totals.currentPeriod.revenue;
                  const changeUsers = totals.currentPeriod.users;
                  const changeOrders = totals.currentPeriod.orders;

                  return (
                    <Tooltip
                      key={idx}
                      classes={{ tooltip: classes.tooltip }}
                      title={
                        <MapTooltip
                          {...this.props}
                          changeRevenue={changeRevenue}
                          changeUsers={changeUsers}
                          changeOrders={changeOrders}
                          isUsa={isUsa}
                          g={g}
                          totals={totals}
                          int={int}
                        />
                      }
                      disableFocusListener
                      disableTouchListener
                      disableHoverListener
                      onMouseEnter={this.geographyOnMouseEnter(g?.properties?.id || g?.properties?.iso_a3)}
                      onMouseLeave={this.geographyOnMouseLeave(g?.properties?.id || g?.properties?.iso_a3)}
                      open={selectedGeography[g?.properties?.id || g?.properties?.iso_a3] === true}
                    >
                      <Geography
                        key={g.rsmKey}
                        geography={g}
                        style={{
                          default: {
                            fill: this.getColorFromPallet(
                              type,
                              type === 'revenue' ? changeRevenue : type === 'users' ? changeUsers : changeOrders
                            ),
                            stroke: '#FFF',
                            strokeWidth: 1,
                            outline: 'none',
                          },
                          hover: {
                            fill: '#2196f3',
                            stroke: '#FFF',
                            strokeWidth: 1,
                            outline: 'none',
                          },
                          pressed: {
                            fill: '#2196f3',
                            stroke: '#FFF',
                            strokeWidth: 1,
                            outline: 'none',
                          },
                        }}
                      />
                    </Tooltip>
                  );
                });
              }}
            </Geographies>
            {/*<Markers>*/}
            {/*  {salesCoordinates.map((c, idx) => {*/}
            {/*    const share = Number((c.share / 10).toFixed(0)) + 3;*/}
            {/*    const currentMarker = `${c.city}, ${c.stateProvince} ${c.postalCode}, ${c.totalOrders}`;*/}
            {/*    return (*/}
            {/*      <Tooltip*/}
            {/*        key={idx}*/}
            {/*        classes={{ tooltip: classes.tooltip }}*/}
            {/*        title={(*/}
            {/*          <Fragment>*/}
            {/*            <div className={classNames(classes.flex, classes.spaceBetween)}>*/}
            {/*              <Typography*/}
            {/*                variant="body2"*/}
            {/*              >{`${c.city}, ${c.stateProvince} ${c.postalCode}`}*/}
            {/*              </Typography>*/}
            {/*            </div>*/}
            {/*            <div className={classNames(classes.flex, classes.spaceBetween)}>*/}
            {/*              <div*/}
            {/*                className={classNames(classes.flexColumn, classes.preventNewLine, classes.sourceWidth)}*/}
            {/*              >*/}
            {/*                <Typography variant="body2">*/}
            {/*                  {`${c.totalOrders}  new order${c.totalOrders > 1 ? 's' : ''}`}*/}
            {/*                </Typography>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*          </Fragment>*/}
            {/*        )}*/}
            {/*        disableFocusListener*/}
            {/*        disableTouchListener*/}
            {/*        disableHoverListener*/}
            {/*        onMouseEnter={this.markerOnMouseEnter(currentMarker)}*/}
            {/*        onMouseLeave={this.markerOnMouseLeave}*/}
            {/*        open={currentMarker === selectedMarker}*/}
            {/*      >*/}
            {/*        <Marker*/}
            {/*          marker={{ coordinates: c.coordinates }}*/}
            {/*          style={{*/}
            {/*            default: { fill: '#00bcd4', outline: 'none' },*/}
            {/*            hover: { fill: '#00bcd4', outline: 'none' },*/}
            {/*            pressed: { fill: '#00bcd4', outline: 'none' },*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          <circle*/}
            {/*            cx={0}*/}
            {/*            cy={0}*/}
            {/*            r={share < 3 ? 3 : share}*/}
            {/*            style={{*/}
            {/*              stroke: '#FFF',*/}
            {/*              strokeWidth: 0.5,*/}
            {/*              opacity: 1,*/}
            {/*              outline: 'none',*/}
            {/*            }}*/}
            {/*          />*/}
            {/*        </Marker>*/}
            {/*      </Tooltip>*/}
            {/*    );*/}
            {/*  })}*/}
            {/*</Markers>*/}
          </ComposableMap>
        </div>
      </Paper>
    );
  }
}

export default connect({ styles })(Map);
