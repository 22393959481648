import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Card, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';

import connect from '../../lib/connect';

interface Props extends GlobalProps {
  title: string;
  period: string;
  currentAmount: number;
  previewsAmount: number;
  formatterStyle?: 'decimal' | 'currency';
  currency?: string;
  formatCode?: string;
  className?: string;
}

const styles = (theme: any): any => ({ root: { padding: theme.spacing(1) } });

const changeBox = (props: Props): any => {
  const { currency } = props;
  const [currentAmountStart, setCurrentAmountStart] = useState(0);
  const [currentAmountEnd, setCurrentAmountEnd] = useState(props.currentAmount);
  const [previewsAmountStart, setPreviewsAmountStart] = useState(0);
  const [previewsAmountEnd, setPreviewsAmountEnd] = useState(props.previewsAmount);
  const [changeStart, setChangeStart] = useState(0);
  const [changeEnd, setChangeEnd] = useState(
    !props.previewsAmount && props.currentAmount ? 100 : (props.currentAmount / props.previewsAmount) * 100 - 100 || 0
  );
  const currencySymbol = currency ? props.getCurrencySimbl(currency) : props.formatterStyle ? '&' : '';

  useEffect(() => {
    setCurrentAmountStart(currentAmountEnd);
    setPreviewsAmountStart(previewsAmountEnd);
    setCurrentAmountEnd(props.currentAmount);
    setPreviewsAmountEnd(props.previewsAmount);
    setChangeStart(changeEnd);
    setChangeEnd(
      !props.previewsAmount && props.currentAmount ? 100 : (props.currentAmount / props.previewsAmount) * 100 - 100 || 0
    );
  }, [props.currentAmount, props.previewsAmount]);

  const { classes, title, period, formatterStyle, className } = props;

  return (
    <Card className={classNames(classes.root, className || null)}>
      <Typography variant="subtitle2" color="textSecondary">
        {title}
      </Typography>
      <Typography variant="h4">
        {currencySymbol ? (
          <CountUp
            separator=","
            decimals={2}
            prefix={currencySymbol}
            start={currentAmountStart}
            end={currentAmountEnd}
          />
        ) : (
          <CountUp separator=" " start={currentAmountStart} end={currentAmountEnd} />
        )}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {period}
      </Typography>
      <Typography variant="h5">
        {currencySymbol ? (
          <CountUp
            separator=","
            decimals={2}
            prefix={currencySymbol}
            start={previewsAmountStart}
            end={previewsAmountEnd}
          />
        ) : (
          <CountUp separator=" " start={previewsAmountStart} end={previewsAmountEnd} />
        )}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        CHANGE
      </Typography>
      <Typography className={classes.flex} variant="subtitle1" color={changeEnd > 0 ? 'primary' : 'secondary'}>
        <CountUp decimals={2} suffix="%" start={changeStart} end={changeEnd} />
        {changeEnd ? changeEnd > 0 ? <ArrowDropUp /> : <ArrowDropDown /> : null}
      </Typography>
    </Card>
  );
};

export default connect({ styles })(changeBox);
